import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "What Is A Sales Consultant?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "A sales consultant finds customers who buy products from their company. A sales consultant often meets with clients in and out of the office to get information about what they think about the product and sell more products to them in the process."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Why Do I Need A Sales Consultant?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Making sales is important, especially to small businesses in order to generate funds and function. Codestaff sales consultants are responsible for helping your business find customers or clients with their persuasive ability and outgoing personality."
        }
    }
}

const SalesConsultant = () => (
    <MainWrapper>
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
            <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
        </Helmet>
        <main>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Sales Consultants.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Sales Consultants. Top
                                    companies and start-ups choose Codestaff professional Sales
                                    Consultants for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Sales Consultant now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE SALES CONSULTANTS' banner='/sales-consultant.png' bannerAlt='sales consultant banner' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default SalesConsultant