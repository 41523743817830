import React from 'react'
import Layout from '../components/layout'
import SalesConsultant from '../components/categories/sales-consultant'
import Seo from '../components/seo'

const SalesConsultantPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SalesConsultant />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Sales Consultant Freelancers | Codestaff'
  const description =
    'Hire the best Sales Consultant freelancers at Codestaff. Get the top 1% of Sales Consultant professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default SalesConsultantPage
